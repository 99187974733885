<template>
  <div
    class="headline-container"
    :class="[
      `headline-container--${styling}`,
      {
        'headline-container--no-margin': noMargin,
        'headline-container--large-margin': largeMargin,
        'headline-container--animate': animate,
      },
    ]"
  >
    <div
      class=""
      :class="{
        'justify-center': center && icon,
        'text-center': center && !icon,
        'flex-row-sm items-center': icon && finishHydrating,
      }"
    >
      <span v-if="icon && finishHydrating" :class="[`icon-${icon}`]"></span>
      <component
        :is="level"
        class="headline"
        :class="[
          'headline--' + styling,
          'text-' + color,
          light ? 'headline--light' : '',
          bold ? 'headline--bold' : '',
          hero ? 'headline--hero' : '',
        ]"
      >
        <span v-html="inpettoTitle ? inpettoTitle : title"></span>
      </component>
    </div>
    <span v-if="subheadline" class="subheadline" v-html="subheadline"> </span>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();
const temp = useTempStore();
const { isHydrating, finishHydrating } = checkHydration();

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  subheadline: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  level: {
    type: String,
    default: "h2",
  },
  styling: {
    type: String,
    default: "h2",
  },
  color: {
    type: String,
    default: "black",
  },
  light: {
    type: Boolean,
    default: false,
  },
  bold: {
    type: Boolean,
    default: false,
  },
  center: {
    type: Boolean,
    default: false,
  },
  noMargin: {
    type: Boolean,
    default: false,
  },
  largeMargin: {
    type: Boolean,
    default: false,
  },
  animate: {
    type: Boolean,
    default: false,
  },
  hero: {
    type: Boolean,
    default: false,
  },
});

const inpettoTitle = ref<string | null>(null);

const logoSvg =
  '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1080 351" style="enable-background:new 0 0 1080 351" xml:space="preserve"><path class="st0" d="M11.2,35.8c0-15.4,13.3-27.4,29.1-27.4c16.2,0,29.1,11.9,29.1,27.4s-13,27.7-29.1,27.7C24.5,63.5,11.2,51.2,11.2,35.8z M17.5,256.3v-164h46v164H17.5z"/><path class="st0" d="M255.9,166.4v89.9h-46v-87.4c0-25.6-15.4-37.2-31.2-37.2c-16.5,0-36.9,8.8-36.9,38.6v86h-46v-164h46V119c7.7-21.4,33.4-30.9,49.2-30.9C233.8,88.1,256.2,117.2,255.9,166.4z"/><path class="st0" d="M461.6,174.1c0,53-38.3,86.4-80.4,86.4c-18.6,0-41.1-7.7-52.7-24.6v100.8h-46V92.3h46v20c11.6-16.5,34.1-24.2,52.7-24.2C423.3,88.1,461.6,121.8,461.6,174.1z M414.6,174.5c0-24.2-19-43.5-42.1-43.5c-22.5,0-42.5,18.6-42.5,43.5c0,24.9,21.1,43.2,42.5,43.2C395.6,217.6,414.6,198.7,414.6,174.5z"/><path class="st0" d="M651.6,189.2H528.3c4.9,19.3,20.4,30.5,42.1,30.5c16.9,0,33-7.7,43.2-19.3l26.7,27c-15.4,20.7-43.9,33-72.7,33c-50.6,0-87.4-35.5-87.4-86c0-53,40.4-86.4,87.1-86.4c47,0,85.3,33.4,85.3,83.6C652.6,176.2,652.3,181.8,651.6,189.2z M606.3,159.4c-1.4-19.3-17.2-31.6-37.9-31.6c-19,0-35.1,9.5-40,31.6H606.3z"/><polygon class="st0" points="849.2,92.3 849.2,32.6 803.2,32.6 803.2,92.3 775.1,92.3 773.3,92.3 740.3,92.3 740.3,32.6 694.3,32.6 694.3,92.3 664.5,92.3 664.5,128.1 694.3,128.1 694.3,256.3 740.3,256.3 740.3,128.1 773.3,128.1 775.1,128.1 803.2,128.1 803.2,256.3 849.2,256.3 849.2,128.1 883.9,128.1 883.9,92.3"/><path class="st0" d="M980.9,91.3c-46.7,0-88.1,34.4-88.1,86.4c0,51.6,41.4,86,88.1,86s88.1-34.4,88.1-86C1069,125.7,1027.6,91.3,980.9,91.3z M1023.9,190.4l-22.5,22.6c-5.5,5.6-13,8.4-20.5,8.4c-6,0-12-1.8-17.1-5.6c-0.1-0.1-0.2-0.2-0.3-0.2c-1.1-0.8-2.1-1.7-3-2.7l-22.5-22.6c-11.3-11.4-11.3-29.8,0-41.1c5.5-5.5,12.8-8.6,20.5-8.6c7.7-0.2,15,3,20.5,8.4l2.1,2.1l2-2c5.4-5.5,12.7-8.5,20.4-8.5c0,0,0,0,0.1,0c7.7,0,14.9,3,20.4,8.4C1035.2,160.6,1035.2,179,1023.9,190.4z"/></svg>';

onMounted(() => {
  if (props.title && props.title.includes("inpetto")) {
    inpettoTitle.value = props.title.replace(
      "inpetto",
      `<span class='headline-logo'><span class='headline-logo__text'>inpetto</span><div class="headline-logo__logo">${logoSvg}</div></span>`
    );
  }
});
</script>

<style lang="scss">
.headline-container {
  // overflow: hidden;

  &--animate {
    .headline,
    .subheadline {
      transform: translate3d(0, 30px, 0);
      opacity: 0;
      animation: showHeadline 1s ease-in-out forwards;
    }

    .subheadline {
      animation-delay: 0.5s;
    }
  }
}

@keyframes showHeadline {
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.headline-logo {
  position: relative;
}

.headline-logo__text {
  opacity: 0;
}

.headline-logo__logo {
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(70%);

  svg {
    width: 100%;
    // height: 100%;
  }
}
</style>
